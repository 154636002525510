import React, { useEffect, useState } from 'react';

import DataTable from '../../components/DataTable';
import CheckboxDropdown from '../../components/CheckboxDropdown';
import DocumentTitle from '../../components/DocumentTitle';

import styles from './styles.scss';
import appStyles from '../../styles/app.scss';

import {
  fetchData,
  Gender,
  Age,
  Education,
  categories,
} from '../../services/dataService';

const AvailabilityScreen: React.FC = () => {
  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    [],
  );
  let mounted = true;

  const getAndSetData = async () => {
    const fetchedData = await fetchData();
    if (mounted) setData(fetchedData);
  };

  useEffect(() => {
    getAndSetData();
    return () => (mounted = false);
  }, []);

  const addToSelection = (toAdd: Array<string>) => {
    const newArray: Array<string> = [];
    categories.forEach((item) => {
      if (selectedCategories.includes(item) || toAdd.includes(item))
        newArray.push(item);
    });
    setSelectedCategories(newArray);
  };

  const removeFromSelection = (toRemove: Array<string>) => {
    setSelectedCategories(
      selectedCategories.filter((item) => !toRemove.includes(item)),
    );
  };

  const toggleParentActive = (
    parent: HTMLElement,
    active: boolean,
    classToToggle: string,
  ) => {
    if (!active && parent.classList.contains(classToToggle))
      parent.classList.toggle(classToToggle);
    else if (active && !parent.classList.contains(classToToggle))
      parent.classList.toggle(classToToggle);
  };

  const onSelectionChange = (box: HTMLInputElement) => {
    const index = selectedCategories.indexOf(box.name);
    if (!box.checked && index !== -1) {
      removeFromSelection([box.name]);
    } else if (box.checked && index === -1) {
      addToSelection([box.name]);
    }
    toggleParentActive(box.parentElement, box.checked, appStyles.checked);
  };

  const getDropDown = (group: object, name: string) => {
    return (
      <CheckboxDropdown
        name={name}
        categorieGroup={group}
        activeItems={selectedCategories}
        changeEvent={onSelectionChange}
        selectAll={addToSelection}
        selectNone={removeFromSelection}
      />
    );
  };

  return (
    <div className={styles.container}>
      <DocumentTitle name={'Paneltool - Availability'} />
      <div className={styles.content}>
        <div className={styles.selectContainer}>
          {getDropDown(Gender, 'geslacht')}
          {getDropDown(Age, 'leeftijd')}
          {getDropDown(Education, 'opleiding')}
        </div>
        <DataTable data={data} selectedCategories={selectedCategories} />
      </div>
    </div>
  );
};

export default AvailabilityScreen;
