import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.scss';
import appStyles from '../../styles/app.scss';

import {
  IProject,
  saveProjectReq,
  intervalOptions,
  typeOptions,
  allowedDomainsOptions,
} from '../../services/projectService';
import { getUserEmail } from '../../services/userService';

import { Input, InputLabel, InputField, InputError } from '../Input';
import Select, { ISelectOption } from '../Select';
import CustomDatePicker from '../CustomDatePicker';
import Button from '../Button';
import Row from '../Row';
import Column from '../Column';
import { Form, FormHead, FormBody, FormFooter } from '../Form';
import { UserContext } from '../../providers/UserProvider';

interface IProps {
  onClose: () => void;
  saveSuccess?: () => void;
  setMessage?: (message: string) => void;
}

const today = new Date();

const ProjectForm: React.FC<IProps> = ({
  onClose,
  saveSuccess,
  setMessage,
}: IProps) => {
  const [saveErrors, setSaveErrors] = useState<
    Array<{ name: string; description: string }>
  >([]);
  const [url, setUrl] = useState<string>('');
  const [allowedDomains, setAllowedDomains] = useState<Array<ISelectOption>>(null);
  const [code, setCode] = useState<string>('');
  const [client, setClient] = useState<string>('');
  const [type, setType] = useState<ISelectOption>(typeOptions[0]);
  const [name, setName] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [interval, setInterval] = useState<ISelectOption>(intervalOptions[0]);
  const [deadline, setDeadline] = useState<string>(today.toISOString().substr(0, 10));

  const user = useContext(UserContext);

  let mounted = true;

  useEffect(() => {
    return () => (mounted = false);
  }, []);

  const getFormDataObject = (): IProject => {
    return {
      allowedDomains: [allowedDomains[0].value],
      url,
      code,
      client,
      type: type.value.toUpperCase(),
      name,
      contactName,
      interval: interval.value.toUpperCase(),
      deadline: deadline,
    };
  };

  const onDatePicked = (date: Date | null) => {
    if(date !== null){
      mounted && setDeadline(date.toISOString().substr(0, 10));
    } else {
      mounted && setDeadline(null);
    }
  };

  const sendForm = async () => {
    let result = await saveProjectReq(getFormDataObject(), getUserEmail(user));
    if (result.success) {
      setMessage('Project with code ' + code + ' successfully saved.');
      saveSuccess();
      onClose();
    } else {
      if (result.errors.length > 0) mounted && setSaveErrors(result.errors);
      console.log(result.errors);
    }
  };

  const getError = (field: string): string => {
    let errorDescription: string;
    if (saveErrors.length == 0) return '';
    saveErrors.forEach((error) => {
      if (error.name == field) errorDescription = error.description;
    });
    return errorDescription;
  };

  return (
    <Form
      action={'#'}
      autoComplete={'off'}
      style={{ backgroundColor: 'white', padding: '1rem' }}
    >
      <FormHead>
        <h5>Add Project</h5>
      </FormHead>
      <FormBody>
        <Row style={{marginBottom: '1rem'}}>
          <Input>
            <InputLabel>Link</InputLabel>
            <InputField
              type={'text'}
              id="url"
              onChange={({ target }) => setUrl(target.value)}
              value={url}
            />
          </Input>
        </Row>
        <Row style={{marginBottom: '1rem'}}>
          <Column style={{marginRight: '2rem'}}>
            <Row className={styles.formRow}>
              <Select
                options={allowedDomainsOptions}
                name={'Allowed domains'}
                label={'Allowed domains'}
                onChange={(option) => setAllowedDomains([option])}
                // isMulti={true}
                value={allowedDomains}
              />
            </Row>
            <Row className={styles.formRow}>
              <Input>
                <InputLabel>Client</InputLabel>
                <InputField
                  type={'text'}
                  id="client"
                  onChange={({ target }) => setClient(target.value)}
                  value={client}
                />
              </Input>
            </Row>
            <Row className={styles.formRow}>
              <Input>
                <InputLabel>Name</InputLabel>
                <InputField
                  type={'text'}
                  id="name"
                  onChange={({ target }) => setName(target.value)}
                  value={name}
                />
              </Input>
            </Row>
            <Row className={styles.formRow}>
              <CustomDatePicker
                label={'Deadline'}
                onPicked={onDatePicked}
                closeOnPick={true}
                showWeekNumbers={true}
                value={deadline}
                minDate={today}
              />
            </Row>
          </Column>
          <Column>
            <Row className={styles.formRow}>
              <Input>
                <InputLabel>Code</InputLabel>
                <InputField
                  type={'text'}
                  id="code"
                  onChange={({ target }) => setCode(target.value)}
                  value={code}
                />
              </Input>
            </Row>
            <Row className={styles.formRow}>
              <Select
                options={typeOptions}
                name={'Type'}
                label={'Type'}
                onChange={( option ) => setType(option)}
                value={type}
              />
            </Row>
            <Row className={styles.formRow}>
              <Input>
                <InputLabel>Contact name</InputLabel>
                <InputField
                  type={'text'}
                  id="contactName"
                  onChange={({ target }) => setContactName(target.value)}
                  value={contactName}
                />
              </Input>
            </Row>
            <Row className={styles.formRow}>
              <Select
                options={intervalOptions}
                name={'Interval'}
                label={'interval'}
                onChange={( option ) => setInterval(option)}
                value={interval}
              />
            </Row>
          </Column>
        </Row>
      </FormBody>
      <FormFooter>
        <Button onClick={() => onClose()} className={appStyles.warning}>
          Cancel
        </Button>
        <Button className={appStyles.primary} onClick={() => sendForm()}>
          Create
        </Button>
      </FormFooter>
    </Form>
  );
};

export default ProjectForm;
