import React, { useEffect, useRef } from 'react';
import styles from './styles.scss';

interface IProps {
  className?: string;
  src: string;
}

const Iframe: React.FC<IProps> = ({ className, src }: IProps) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  // useEffect(() => {
  //   frameRef.current.addEventListener('load', () => {
  //     console.log('iframe loaded');
  //     frameRef.current.contentWindow.postMessage('hello', 'http://localhost');
  //   });
  //   window.addEventListener('message', handleInfo);
  //   return () => {
  //     window.removeEventListener('message', handleInfo);
  //   };
  // }, []);

  const handleInfo = (event: MessageEvent) => {
    console.log('recieved message from ' + event.origin);
    console.log('message: ' + event.data);
  };

  return (
    <div className={styles.container}>
      <iframe
        ref={frameRef}
        className={styles.iframe}
        src={src}
        frameBorder={'0'}
      >
        <p>Could not load iframe</p>
      </iframe>
    </div>
  );
};

export default Iframe;
