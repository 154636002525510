import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  id?: string;
  value: string;
  type: 'text' | 'password' | 'tel' | 'email' | 'date';
  placeholder?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const InputField: React.FC<IProps> = ({
  className,
  id,
  value,
  type = 'text',
  placeholder,
  readOnly,
  isDisabled,
  name,
  onChange,
  style,
}: IProps) => {
  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  return (
    <input
      className={classNames(styles.inputField, className)}
      id={id}
      type={type}
      onChange={inputChange}
      value={value}
      placeholder={placeholder}
      name={name}
      readOnly={readOnly}
      style={style}
      disabled={isDisabled}
    />
  );
};

export default InputField;
