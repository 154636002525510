import React, { useEffect } from 'react';

interface IProps {
  name: string;
}

const DocumentTitle: React.FC<IProps> = ({ name }: IProps): null => {
  useEffect(() => {
    document.title = name;
  }, []);

  return null;
};

export default DocumentTitle;
