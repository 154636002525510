import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const PopUpMenuButton: React.FC<IProps> = ({
  className,
  children,
  onClick,
  style,
}: IProps) => {
  return (
    <div
      className={classNames(styles.popUpMenuButton, className)}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default PopUpMenuButton;
