import React, { useState } from 'react';
import styles from './styles.scss';

import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import ContentContainer from '../../components/ContentContainer';

interface IProps {
  children: React.ReactNode;
}

const Base: React.FC<IProps> = ({ children }: IProps) => {
  const [navActive, setNavActive] = useState<boolean>(false);

  const switchNav = () => {
    setNavActive((active) => !active);
  };

  return (
    <>
      <Header switchNav={switchNav} />
      <div className={styles.body}>
        <Navigation active={navActive} switchActive={switchNav} />
        <ContentContainer>{children}</ContentContainer>
      </div>
    </>
  );
};

export default Base;
