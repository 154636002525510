enum Routes {
  HOME = '/',
  AVAILABILITY = '/availability',
  PROJECTS = '/projects'
}

import HomeScreen from '../containers/HomeScreen';
import AvailabilityScreen from '../containers/AvailabilityScreen';
import ProjectsScreen from '../containers/ProjectsScreen';

import IconHome from '../assets/icon_home_black.svg';
import IconAvailability from '../assets/icon_mail_black.svg';
import IconProjects from '../assets/icon_bar-chart_black.svg';

export interface IPage {
  name: string,
  label: string,
  path: string,
  icon: string,
  component: React.FC,
  allowedDomains?: Array<string>
}

export const pageInfo: Array<IPage> = [
  {
    name: 'home',
    label: 'Home',
    path: Routes.HOME,
    icon: IconHome,
    component: HomeScreen,
  },
  {
    name: 'availability',
    label: 'Availability',
    path: Routes.AVAILABILITY,
    icon: IconAvailability,
    component: AvailabilityScreen,
  },
  {
    name: 'projects',
    label: 'Projects',
    path: Routes.PROJECTS,
    icon: IconProjects,
    component: ProjectsScreen,
    allowedDomains: [
      'memo2.nl'
    ]
  },
];

export default Routes;
