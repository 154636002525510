const url = 'https://www.e-research.nl/xspanelapi/MailStats/';

export enum Gender {
  MALE = 'Man',
  FEMALE = 'Vrouw',
}

export enum Age {
  UNDER_24 = 'Onder 24',
  FROM_25_TO_34 = '25-34',
  FROM_35_TO_44 = '35-44',
  FROM_45_TO_54 = '45-54',
  FROM_55_TO_64 = '55-64',
  ABOVE_65 = 'Boven 65',
}

export enum Education {
  LOW = 'laag',
  AVERAGE = 'midden',
  HIGH = 'hoog',
}

export const categories = [
  'Man',
  'Vrouw',
  'Onder 24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  'Boven 65',
  'laag',
  'midden',
  'hoog',
];

export const fetchData = async () => {
  return await fetch(url)
    .then((res) => res.json())
    .then((data) => data);
};
