import React, { useEffect, useRef } from 'react';
import styles from './styles.scss';
import classNames from 'classnames';

import { clickedOutside } from '../../utils/index';

interface IProps {
  children?: React.ReactNode;
  blur?: boolean;
  darken?: boolean;
  onClickOutside?: () => void;
}

const Modal: React.FC<IProps> = ({
  children,
  blur,
  darken,
  onClickOutside,
}: IProps) => {
  const modalChildRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (clickedOutside(modalChildRef, event)) {
      onClickOutside && onClickOutside();
    }
  };

  const setClickOutsideListener = () => {
    window.addEventListener('mousedown', handleClickOutside);
  }

  useEffect(() => {
    if (modalChildRef.current) {
      setTimeout(setClickOutsideListener, 100);
      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  return (
    <div
      className={classNames(
        styles.modal,
        { [styles.modalBlurred]: blur },
        { [styles.modalDarkened]: darken },
      )}
    >
      <div ref={modalChildRef} className={styles.modalChildren}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
