import React, { createContext, useEffect, useState } from 'react';
import { firebaseAuth } from '../firebase';

export const UserContext = createContext<firebase.default.User>(null);

interface IProps {
  children?: React.ReactNode;
}

const UserProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<firebase.default.User>(null);
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    }, error => console.log('auth error: '));
  }, []);

  if(loading) return <div>Loading..</div>
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserProvider;
