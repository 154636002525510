import React, { useEffect, useRef, createRef, useState } from 'react';
import styles from './styles.scss';
import appStyles from '../../styles/app.scss';

interface IProps {
  name: String;
  categorieGroup: Object;
  activeItems: Array<string>;
  changeEvent: (checkbox: HTMLInputElement) => void;
  selectAll: (toAdd: Array<string>) => void;
  selectNone: (toRemove: Array<string>) => void;
}

const CheckboxDropdown: React.FC<IProps> = ({
  name,
  categorieGroup,
  activeItems,
  changeEvent,
  selectAll,
  selectNone,
}: IProps) => {
  const [opened, setOpened] = useState<boolean>(false);

  const itemContainer = useRef<HTMLDivElement>();
  const checkboxes = useRef<Array<React.RefObject<HTMLInputElement>>>([]);
  const checkAllRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (checkboxes.current.length == 0) {
      Object.values(categorieGroup).forEach((item, index) => {
        checkboxes.current[index] = createRef();
      });
    }
  }, []);

  const switchOpened = () => {
    itemContainer.current.parentElement.classList.toggle(styles.opened);
    setOpened(!opened);
  };

  const checkAllOrNone = () => {
    let allChecked = true;
    let changeTo: boolean;

    checkboxes.current.forEach((box) => {
      if (!box.current.checked) {
        allChecked = false;
      }
    });

    if (allChecked) changeTo = false;
    else changeTo = true;

    checkboxes.current.forEach((box) => {
      box.current.checked = changeTo;
    });
    if (changeTo) {
      selectAll(Object.values(categorieGroup));
      checkAllRef.current.style.backgroundColor = '#aad7a8';
    } else {
      selectNone(Object.values(categorieGroup));
      checkAllRef.current.style.backgroundColor = 'transparent';
    }
  };

  const checkIfAllActive = () => {
    let allChecked = true;
    checkboxes.current.forEach((box) => {
      if (!box.current.checked) {
        allChecked = false;
      }
    });
    if (allChecked) checkAllRef.current.style.backgroundColor = '#aad7a8';
    else checkAllRef.current.style.backgroundColor = 'transparent';
  };

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeEvent(event.target);
    checkIfAllActive();
  };

  const getNameBox = () => {
    return (
      <div className={styles.nameBox}>
        <span className={styles.name} onClick={switchOpened}>
          {name}
        </span>
        <div
          className={styles.nameBoxSwitch}
          ref={checkAllRef}
          onClick={checkAllOrNone}
        ></div>
      </div>
    );
  };

  const getBoxes = () => {
    let boxes = Object.values(categorieGroup).map((item, index) => (
      <div key={'div-' + item} className={styles.dropdownItem}>
        <label
          htmlFor={item + '-checkbox'}
          key={'label-' + item}
          className={
            styles.dropdownItemLabel +
            ' ' +
            (activeItems.includes(item) ? appStyles.checked : '')
          }
        >
          <input
            id={item + '-checkbox'}
            key={item}
            name={item}
            ref={checkboxes.current[index]}
            type="checkbox"
            onChange={change}
            defaultChecked={activeItems.includes(item)}
          />
          {item}
        </label>
      </div>
    ));
    return boxes;
  };

  return (
    <div className={styles.dropdownContainer}>
      {getNameBox()}
      <div className={styles.dropdownItemsContainer} ref={itemContainer}>
        {getBoxes()}
      </div>
    </div>
  );
};

export default CheckboxDropdown;
