import React from 'react';

import UserProvider from './providers/UserProvider';
import Routing from './Routing';

const App = () => {
  return (
    <UserProvider>
      <Routing />
    </UserProvider>
  );
};

export default App;
