import { firebaseAuth } from '../firebase';
import { getDomainFromEmail } from '../utils/index';

export const getUser = () => {
  return firebaseAuth.currentUser;
};

export const getUserEmail = (_user?: firebase.default.User) => {
  let user: firebase.default.User;
  if(_user) user = _user;
  else user = getUser();
  if (user) {
    return user.email;
  } else {
    return null;
  }
};

export const getUserDomain = (user?: firebase.default.User) => {
  const userEmail = getUserEmail(user);
  if (userEmail) {
    return getDomainFromEmail(userEmail);
  } else {
    return null;
  }
};
