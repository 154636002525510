import React, { useEffect, useState } from 'react';
import styles from './styles.scss';

import { Gender, Age, Education } from '../../services/dataService';

type categorieData = {
  dagen: number;
  aantal: number;
  geslacht: Gender;
  leeftijd: Age;
  opleiding: Education;
};

interface IProps {
  data: Array<categorieData>;
  selectedCategories: Array<string>;
}

const DataTable: React.FC<IProps> = ({ data, selectedCategories }: IProps) => {
  const [dataByDay, setDataByDay] = useState<Array<Array<categorieData>>>([]);
  // const valuesPerDay: Array<number> = [];

  useEffect(() => {
    if (data.length) {
      setDataByDay(groupDataByDay());
    }
  }, [data]);

  const uniqueDays = [...new Set(data.map((item) => item.dagen))];

  const formatNumber = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  };

  const groupDataByDay = () => {
    if (data.length == 0) return [];
    let newArray = Array<Array<categorieData>>();

    uniqueDays.map((day) => {
      let dayArray = Array<categorieData>();

      data.map((item) => {
        if (item.dagen === day) dayArray.push(item);
      });

      newArray.push(dayArray);
    });
    return newArray;
  };

  const getTotalBySelectionAndDay = (day: number) => {
    if (selectedCategories.length == 0) return 0;
    let total = 0;
    let indexOfDay = uniqueDays.indexOf(day);
    dataByDay[indexOfDay].forEach((item) => {
      if (
        selectedCategories.includes(item.geslacht) ||
        !enumValueInSelection(Gender)
      )
        if (
          selectedCategories.includes(item.leeftijd) ||
          !enumValueInSelection(Age)
        )
          if (
            selectedCategories.includes(item.opleiding) ||
            !enumValueInSelection(Education)
          )
            total += item.aantal;
    });
    // Should numbers add up over the days? e.g. day 2's count is day 1 + day 2
    // if (uniqueDays.indexOf(day) > 0) total += valuesPerDay[day - 1];
    // valuesPerDay[day] = total;
    return total;
  };

  const enumValueInSelection = (enumToCheck: Object) => {
    let exists = false;
    Object.values(enumToCheck).forEach((enumValue) => {
      if (selectedCategories.includes(enumValue)) {
        exists = true;
      }
    });
    return exists;
  };

  // For counting the total numbers of 1 categorie, maybe show this when none are selected?
  // const getTotalByCategoryAndDay = (categorie: string, day: number) => {
  // let total = 0;
  // for (let i = 0; i < dataByDay[day].length; i++) {
  //   const element = dataByDay[day][i];
  //   if (Object.values(element).includes(categorie)) {
  //     total += element.aantal;
  //   }
  // }
  // return total;
  // };

  const getDayString = (day: number) => {
    let dayString = day + ' dagen geleden';
    if (day == 0) dayString = 'Vandaag';
    if (day == 1) dayString = 'Gisteren';
    return dayString;
  };

  const getTable = () => {
    return (
      <table className={styles.table}>
        {getTableHead()}
        {getTableBody()}
      </table>
    );
  };

  const getTableHead = () => {
    return (
      <thead>
        <tr>
          <td>Categorie</td>
          {uniqueDays.map((day) => (
            <td key={day}>{getDayString(day)}</td>
          ))}
        </tr>
      </thead>
    );
  };

  const getTableBody = () => {
    // total for each category. default ??
    // let tableRows = selectedCategories.map((categorie) => (
    //   <tr key={'row-' + categorie}>
    //     <td key={categorie}>{categorie}</td>
    //     {uniqueDays.map((day) => (
    //       <td key={categorie + '-' + day}>
    //         {getTotalByCategoryAndDay(categorie, day)}
    //       </td>
    //     ))}
    //   </tr>
    // ));
    // return <tbody>{tableRows}</tbody>;

    // total for whole selection.
    let row = (
      <tr>
        <td>
          {selectedCategories.length
            ? selectedCategories
                .map((categorie) =>
                  categorie.replace('-', '\u2011').replace(' ', '\u00A0'),
                )
                .join(' | ')
            : 'None selected'}
        </td>
        {uniqueDays.map((day) => (
          <td key={day}>{formatNumber(getTotalBySelectionAndDay(day))}</td>
        ))}
      </tr>
    );
    return <tbody>{row}</tbody>;
  };

  return (
    <div className={styles.tableContainer}>
      {dataByDay.length ? getTable() : <h2>Loading data...</h2>}
    </div>
  );
};

export default DataTable;
