import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.scss';
import appStyles from '../../styles/app.scss';

import {
  IProject,
  IResponse,
  deleteProjectReq,
} from '../../services/projectService';
import { getUserEmail } from '../../services/userService';

import { UserContext } from '../../providers/UserProvider';

import { capitalize } from '../../utils';

import Button from '../Button';
import Modal from '../Modal';
import Iframe from '../Iframe';
import { getNumberOfWeek } from '../../services/datePickerService';

let onlineStyleSheet =
  '&_css=https://storage.memo2.space/dev/panel-tool/paneltool_quota-table_styles.css';

interface IProps {
  project: IProject;
  onDelete?: () => void;
  setMessage?: (message: string) => void;
}

const Project: React.FC<IProps> = ({
  project,
  onDelete,
  setMessage,
}: IProps) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const user = useContext(UserContext);

  let mounted = true;

  useEffect(() => {
    return () => (mounted = false);
  }, []);

  const getDateFromDeadline = (isoDate: string): string => {
    let date = new Date(isoDate.split('T')[0]);
    return (
      date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    );
  };

  const deleteProject = async () => {
    let deleteResult: IResponse = await deleteProjectReq(
      project.code,
      getUserEmail(user),
    );
    if (!deleteResult.success) {
      setMessage('something went wrong while deleting project ' + project.code);
      return;
    }
    setMessage('succesfully deleted project with code ' + project.code);
    if (mounted) {
      setConfirmDelete(false);
    }
    onDelete();
  };

  return (
    <div key={project.code} className={styles.projectContainer}>
      {confirmDelete &&
        <Modal
          blur
          darken
          onClickOutside={() => setConfirmDelete(false)}
        >
          <div className={styles.confirmDelete}>
            <p>
              Are you sure you want to delete project with code {project.code}?
            </p>
            <div className={styles.confirmDeleteControls}>
              <Button
                className={appStyles.button + ' ' + appStyles.warning}
                onClick={() => mounted && setConfirmDelete(false)}
              >
                Cancel
              </Button>
              <Button
                className={appStyles.button + ' ' + appStyles.danger}
                onClick={deleteProject}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      }
      <div className={styles.projectInfo}>
        <div className={styles.infoRow}>
          <div className={styles.infoCol}>
            <p>Code: {project.code}</p>
            <p>Client: {project.client}</p>
            <p>Type: {capitalize(project.type)}</p>
            <p>Name: {project.name ? project.name : '-'}</p>
          </div>
          <div className={styles.infoCol}>
            <p>Interval: {capitalize(project.interval)}</p>
            <p>Deadline date: {getDateFromDeadline(project.deadline)}</p>
            <p>
              Deadline week:{' '}
              {getNumberOfWeek(new Date(project.deadline).getTime())}
            </p>
          </div>
          <div className={styles.infoCol}>
            <p>Contact Name: {project.contactName}</p>
          </div>
          <div className={styles.actionsButton}>
            <div className={styles.actionsMenu}>
              <Button
                className={appStyles.button + ' ' + appStyles.danger}
                onClick={() => mounted && setConfirmDelete(true)}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoCol}>
            <Iframe src={project.url} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
