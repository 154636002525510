import React, { useContext, useState } from 'react';
import styles from './styles.scss';
import appStyles from '../../styles/app.scss';

import {
  firebaseAuth,
  signInWithEmailAndPassword,
  resetPassword,
} from '../../firebase';
import { UserContext } from '../../providers/UserProvider';

import { Input, InputField } from '../Input';
import Button from '../Button';
import {
  PopUpMenu,
  PopUpMenuButton,
  PopUpMenuList,
  PopUpMenuListItem,
} from '../PopUpMenu';

const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="#404040"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="7" r="4" />
    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
  </svg>
);

interface IProps {}

const UserAccount: React.FC<IProps> = ({}: IProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const user = useContext(UserContext);

  const signInHandler = () => {
    signInWithEmailAndPassword(email, password);
  };

  const signOutHandler = () => {
    firebaseAuth.signOut();
  };

  const forgotPasswordHandler = () => {
    resetPassword(email);
  };

  return (
    <div className={styles.container}>
      <PopUpMenu onClickOutside={() => setMenuOpened(false)}>
        <PopUpMenuButton
          className={appStyles.buttonIcon}
          onClick={() => setMenuOpened(!menuOpened)}
          style={{backgroundColor: 'white'}}
        >
          {userIcon}
        </PopUpMenuButton>
        <PopUpMenuList isOpen={menuOpened}>
          {user ? (
            <>
              <PopUpMenuListItem>Logged in as {user.email}</PopUpMenuListItem>
              <PopUpMenuListItem onClick={signOutHandler}>
                Logout
              </PopUpMenuListItem>
            </>
          ) : (
            <>
              <PopUpMenuListItem>
                <Input>
                  <InputField
                    type={'email'}
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    placeholder={'email'}
                  />
                </Input>
              </PopUpMenuListItem>
              <PopUpMenuListItem style={{ borderTop: 0 }}>
                <Input>
                  <InputField
                    type={'password'}
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    placeholder={'password'}
                  />
                </Input>
              </PopUpMenuListItem>
              <PopUpMenuListItem style={{ borderTop: 0 }}>
                <Button onClick={signInHandler} color={'primary'}>
                  Login
                </Button>
                <Button
                  onClick={forgotPasswordHandler}
                  style={{ marginLeft: '0.6rem' }}
                >
                  Reset password
                </Button>
              </PopUpMenuListItem>
            </>
          )}
        </PopUpMenuList>
      </PopUpMenu>
    </div>
  );
};

export default UserAccount;
