import React from 'react';
import styles from './styles.scss';

import ReactSelect from 'react-select';
import { isOptionDisabled } from 'react-select/src/builtins';

interface IProps {
  className?: string;
  name?: string;
  id?: string;
  label?: string;
  options?: Array<ISelectOption>;
  onChange?: (option: ISelectOption) => void;
  value?: Array<ISelectOption> | ISelectOption;
  error?: string;
  defaultValue?: ISelectOption;
  isMulti?: boolean;
  placeHolder?: string;
}

export interface ISelectOption {
  value: string;
  label: string;
}

const customStyle = {
  container: (base: any) => ({
    ...base,
    zIndex: 10
  }),
}

const Select: React.FC<IProps> = ({
  className,
  name,
  id,
  label,
  options,
  onChange,
  value = null,
  error,
  defaultValue,
  isMulti = false,
  placeHolder,
}: IProps) => {

  const changeSelection = (option: ISelectOption | null) => {
    onChange && onChange(option);
  };

  return (
    <div className={styles.select}>
      {label && <label htmlFor={id} className={styles.selectLabel}>{label}</label>}
      <ReactSelect
        inputId={id}
        isMulti={isMulti}
        onChange={changeSelection}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        isClearable={true}
        value={value}
        styles={customStyle}
      />
    </div>
  );
};

export default Select;
