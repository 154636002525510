const oneDayInMs = 60 * 60 * 24 * 1000;

export interface IDateData {
  year: number;
  month: number;
  date: number;
}

export const todayTimestamp =
  Date.now() -
  (Date.now() % oneDayInMs) +
  new Date().getTimezoneOffset() * 1000 * 60;

export interface IMonthDetails {
  index: number;
  nOfDays: number;
  firstDay: number;
  year: number;
  month: number;
}

export interface IDayDetails {
  date: number;
  day: number;
  monthOffset: number;
  timestamp: number;
  dayString: string;
}

export const getNumberOfWeek = (timestamp: number): number => {
  const today = new Date(timestamp);
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear =
    (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const getNumberOfDays = (year: number, month: number): number => {
  return 40 - new Date(year, month, 40).getDate();
};

export const getMonthDetails = (
  year: number,
  month: number,
): Array<IDayDetails> => {
  let firstDay = new Date(year, month).getDay();
  let nOfDays = getNumberOfDays(year, month);
  let monthArray = [];
  let rows = 6;
  let currentDay = null;
  let index = 0;
  let cols = 7;

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      currentDay = getDayDetails({
        index,
        nOfDays,
        firstDay,
        year,
        month,
      });
      monthArray.push(currentDay);
      index++;
    }
  }
  return monthArray;
};

export const getDayDetails = (args: IMonthDetails): IDayDetails => {
  let date = args.index - args.firstDay;
  let day = args.index % 7;
  let prevMonth = args.month - 1;
  let prevYear = args.year;
  if (prevMonth < 0) {
    prevMonth = 11;
    prevYear--;
  }
  let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
  let _date =
    date < 0 ? prevMonthNumberOfDays + date : (date % args.nOfDays) + 1;
  let monthOffset = date < 0 ? -1 : date >= args.nOfDays ? 1 : 0;
  let timestamp = new Date(args.year, args.month, _date).getTime();
  return {
    date: _date,
    day,
    monthOffset,
    timestamp,
    dayString: days[day],
  };
};

export const getDateStringFromTimestamp = (timestamp: number): string => {
  let dateObject = new Date(timestamp);
  let month = dateObject.getMonth() + 1;
  let date = dateObject.getDate();
  return (
    dateObject.getFullYear() +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  );
};

export const getDateFromDateString = (
  dateValue: string,
): { year: number; month: number; date: number } => {
  console.log(dateValue);
  let dateData = dateValue.split('-').map((d) => parseInt(d, 10));
  console.log(dateData);
  if (dateData.length < 3) return null;

  let year = dateData[0];
  let month = dateData[1];
  let date = dateData[2];
  return { year, month, date };
};

export const days: Array<string> = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const months: Array<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
