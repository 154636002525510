import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const InputError: React.FC<IProps> = ({ className, children }: IProps) => {
  return (
    <div className={classNames(styles.inputError, className)}>{children}</div>
  );
};

export default InputError;
