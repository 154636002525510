import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IPage } from '../../Routing/routes';

import { UserContext } from '../../providers/UserProvider';

import { isAllowedToView } from '../../utils/index';

interface IProps {
  pageInfo: IPage;
  redirectPath?: string;
}

const ProtectedRoute: React.FC<IProps> = ({
  pageInfo,
  redirectPath,
}: IProps) => {
  const user = useContext(UserContext);

  if (isAllowedToView(pageInfo, user)) {
    return <Route path={pageInfo.path} exact component={pageInfo.component} />;
  } else {
    return (
      <Route
        render={() => <Redirect to={redirectPath ? redirectPath : '/'} />}
      />
    );
  }
};

export default ProtectedRoute;
