import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: 'memo2-paneltool.firebaseapp.com',
  projectId: 'memo2-paneltool',
  storageBucket: 'memo2-paneltool.appspot.com',
  messagingSenderId: '420258730639',
  appId: '1:420258730639:web:43567f2695d82a31f056fa',
  measurementId: 'G-F59J11RRTL',
};

firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();

export const signInWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  await firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .catch((error) => {
      console.log(error);
    });
};

export const resetPassword = async (email: string) => {
  await firebaseAuth.sendPasswordResetEmail(email).catch((error) => {
    console.log(error);
  });
};
