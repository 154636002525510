import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const PopUpMenuListItem: React.FC<IProps> = ({
  className,
  children,
  onClick,
  style,
}: IProps) => {
  return (
    <div
      className={classNames(styles.popUpMenuListItem, className, {
        [styles.popUpMenuListItemButton]: onClick,
      })}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default PopUpMenuListItem;
