import React from 'react';

import styles from './styles.scss';

import DocumentTitle from '../../components/DocumentTitle';

const HomeScreen: React.FC = () => {
  return (
    <div className={styles.container}>
      <DocumentTitle name={'Paneltool - Home'} />
      <div className={styles.content}>
        <h1>
          Welcome to the MeMo<sup>2</sup> Panel Tool
        </h1>
        <hr />
        <p>
          Here you can see the amount of people that are available for surveys
          and manage veldwerk projects.
        </p>
      </div>
    </div>
  );
};

export default HomeScreen;
