import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './styles.scss';

import { UserContext } from '../../providers/UserProvider';

import {
  fetchProjectsReq,
  fetchFilteredProjectsReq,
  IProject,
  IResponse,
  IFilter,
  ISort,
} from '../../services/projectService';

import { getUserEmail } from '../../services/userService'

import Project from '../../components/Project';
import ProjectForm from '../../components/ProjectForm';
import ProjectListActions from '../../components/ProjectListActions';
import Modal from '../../components/Modal';

interface IProps {
  setMessage: (message: string) => void;
}

const ProjectList: React.FC<IProps> = ({ setMessage }: IProps) => {
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<IFilter>>([]);
  const filtersRef = useRef<Array<IFilter>>(null);
  const user = useContext(UserContext);

  const timer = useRef(null);

  let defaultChangeDelay: number = 0.7 * 1000;

  const onChangeDelay = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(
      () => {
        getAndSetProjects(filtersRef.current)
      },
      defaultChangeDelay,
    );
  };

  filtersRef.current = filters;

  let mounted = true;

  const getAndSetProjects = async (filters?: Array<IFilter>) => {
    let res: IResponse;
    if (Array.isArray(filters) && filters.length > 0) {
      res = await fetchFilteredProjectsReq(filters, getUserEmail(user));
    } else {
      res = await fetchProjectsReq(getUserEmail(user));
    }
    res.message && setMessage(res.message);
    if (res.items === undefined) {
      mounted && setProjects([]);
      return;
    }
    if (mounted) setProjects(res.items);
  };

  useEffect(() => {
    getAndSetProjects();
    return () => (mounted = false);
  }, []);

  const handleFilter = (newFilter: IFilter) => {
    if (newFilter.value == '') {
      setFilters(removeFilter(newFilter));
      return;
    }
    setFilters(addFilter(newFilter));
  };

  const addFilter = (newFilter: IFilter) => {
    let exists = false;
    let newFilters = filters.map((filter) => {
      if (
        filter.field == newFilter.field &&
        filter.filterType == newFilter.filterType
      ) {
        filter.value = newFilter.value;
        exists = true;
      }
      return filter;
    });
    if (!exists) newFilters.push(newFilter);
    filtersRef.current = newFilters;
    onChangeDelay();
    return newFilters;
  };

  const removeFilter = (newFilter: IFilter) => {
    let newFilters = filtersRef.current.filter(
      (filter) =>
        !(
          filter.field == newFilter.field &&
          filter.filterType == newFilter.filterType
        ),
    );
    filtersRef.current = newFilters;
    onChangeDelay();
    return newFilters;
  };

  const handleSort = (sortInfo: ISort) => {
    console.log('handleSort');
    console.table(sortInfo);
  };

  const renderProjects = () => {
    return projects.length > 0 ? (
      projects.map((project) => (
        <Project
          key={project.code}
          project={project}
          setMessage={setMessage}
          onDelete={getAndSetProjects}
        />
      ))
    ) : (
      <h3>No projects found.</h3>
    );
  };

  return (
    <>
      <ProjectListActions
        onCreateAction={() => setShowForm(true)}
        onFilterAction={handleFilter}
        onSortAction={handleSort}
      />
      { showForm && 
        <Modal
          blur
          darken
          onClickOutside={() => setShowForm(false)}
        >
          <ProjectForm
            onClose={() => setShowForm(false)}
            saveSuccess={getAndSetProjects}
            setMessage={setMessage}
          />
        </Modal>
      }
      <div className={styles.projectsContainer}>{renderProjects()}</div>
    </>
  );
};

export default ProjectList;
