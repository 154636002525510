import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  action: string;
  method?: string;
  autoComplete?: 'on' | 'off';
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLFormElement>;
}

const Form: React.FC<IProps> = ({
  action,
  method,
  autoComplete = 'on',
  className,
  children,
  style,
  ref,
}: IProps) => {
  return (
    <form
      ref={ref}
      action={action}
      method={method}
      autoComplete={autoComplete}
      className={classNames(styles.form, className)}
      style={style}
    >
      {children}
    </form>
  );
};

export default Form;
