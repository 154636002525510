import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children: React.ReactNode;
  htmlFor?: string;
}

const InputLabel: React.FC<IProps> = ({
  className,
  children,
  htmlFor,
}: IProps) => {
  return (
    <label
      className={classNames(styles.inputLabel, className)}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

export default InputLabel;
