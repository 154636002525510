import React, { useEffect, useState } from 'react';

import ProjectList from '../../components/ProjectList';
import DocumentTitle from '../../components/DocumentTitle';

import styles from './styles.scss';

const ProjectsScreen: React.FC = () => {
  const [messages, setMessages] = useState<Array<string>>([]);

  let mounted = true;

  useEffect(() => {
    return () => (mounted = false);
  }, []);

  const addMessage = (message: string) => {
    if (mounted) setMessages((messages) => messages.concat(message));
  };
  const removeMessage = (message: string) => {
    if (mounted)
      setMessages((messages) => messages.filter((item) => item != message));
  };

  // not in use at the moment, need to make inbox-like component and small message pop-up
  const getMessages = () => {
    let msgs = messages.map((message) => (
      <p key={message}>
        <span
          className={styles.closeMessage}
          onClick={() => {
            mounted && removeMessage(message);
          }}
        >
          X
        </span>
        {message}
      </p>
    ));
    return <div className={styles.messages}>{msgs}</div>;
  };

  return (
    <div className={styles.container}>
      <DocumentTitle name={'Paneltool - Projects'} />
      <div className={styles.content}>
        <ProjectList setMessage={addMessage} />
      </div>
    </div>
  );
};

export default ProjectsScreen;
