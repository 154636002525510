import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.scss';

import CustomDatePicker from '../CustomDatePicker';
import { Input, InputField } from '../Input';

import { IFilter } from '../../services/projectService';

import Row from '../Row';
import Column from '../Column';

interface IProps {
  name: string;
  onFilter: (filter: IFilter) => void;
}

const RangeFilter: React.FC<IProps> = ({ name, onFilter }: IProps) => {
  const [startValue, setStartValue] = useState<Date | null>(null);
  const [endValue, setEndValue] = useState<Date | null>(null);
  const startFilter = useRef<IFilter>({
    field: name,
    filterType: 'start',
  });
  const endFilter = useRef<IFilter>({
    field: name,
    filterType: 'end',
  });

  let mounted = true;

  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  const onStartSelected = (date: Date | null) => {
    setStartValue(date);
    if(date !== null){
      startFilter.current.value = date.toISOString().split('T')[0];
    } else {
      startFilter.current.value = '';
    }
    onFilter(startFilter.current);
  };

  const onEndSelected = (date: Date | null) => {
    setEndValue(date);
    if(date !== null){
      endFilter.current.value = date.toISOString().split('T')[0];
    } else {
      endFilter.current.value = '';
    }
    onFilter(endFilter.current);
  };

  return (
    <>
      <Row style={{marginBottom: '0.4rem'}}>
        <Column>
          <CustomDatePicker
            onPickedStart={onStartSelected}
            onPickedEnd={onEndSelected}
            selectsRange={true}
            closeOnPick={false}
            value={'pick date'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input>
            <InputField
              type={'text'}
              placeholder={'start'}
              value={startValue ? startValue.toLocaleDateString() : ''}
              readOnly={true}
              isDisabled={true}
            />
          </Input>
        </Column>
        <Column style={{marginLeft: '1rem'}}>
          <Input>
            <InputField
              type={'text'}
              placeholder={'end'}
              value={endValue ? endValue.toLocaleDateString() : ''}
              readOnly={true}
              isDisabled={true}
            />
          </Input>
        </Column>
      </Row>
    </>
  );
};

export default RangeFilter;
