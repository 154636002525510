import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.scss';

import { Input, InputField } from '../../components/Input';
import Select, { ISelectOption } from '../../components/Select';
import { IFilter } from '../../services/projectService';

interface IProps {
  name: string;
  type: string;
  filterType: 'regex' | 'exact' | 'start' | 'end';
  label?: string;
  options?: Array<ISelectOption>;
  className?: string;
  textInputDelay?: number;
  onFilter: (filter: IFilter) => void;
}

const Filter: React.FC<IProps> = ({
  name,
  type,
  filterType,
  label,
  options,
  className,
  textInputDelay,
  onFilter,
}: IProps) => {
  const [value, setValue] = useState<string>('');
  const [selectValue, setSelectValue] = useState<ISelectOption>(null);
  
  const filter = useRef<IFilter>({
    field: name,
    filterType: filterType,
  });
  const timer = useRef(null);

  let defaultChangeDelay: number = 0.7 * 1000;
  let mounted = true;

  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  const onChangeDelay = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(
      sendFilter,
      textInputDelay ? textInputDelay : defaultChangeDelay,
    );
  };

  const sendFilter = () => {
    onFilter(filter.current);
  };

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    filter.current.value = event.target.value.trim();
    if (event.target.type == 'text') onChangeDelay();
    else sendFilter();
  };

  const selectChange = (option: ISelectOption | null) => {
    let filter: IFilter = {
      field: name,
      filterType: filterType
    };
    if (option !== null){
      mounted && setSelectValue(option);
      filter.value = option.value.toUpperCase();
    } else {
      setSelectValue(null);
      filter.value = ''
    }
    onFilter(filter);
  };

  const getInput = () => {
    return (
      <Input>
        <InputField
          type={'text'}
          className={styles.filter}
          placeholder={name}
          onChange={inputChange}
          value={value}
        />
      </Input>
    );
  };

  const getSelect = () => {
    return (
      <Select
        className={styles.filter}
        name={name}
        options={options}
        onChange={selectChange}
        value={selectValue}
        placeHolder={label}
      />
    );
  };

  const getInputDate = () => {
    return (
      <Input>
        <InputField
          type={'date'}
          className={styles.filter}
          placeholder={name}
          onChange={inputChange}
          value={value}
        />
      </Input>
    );
  };

  return (
    <div className={styles.container}>
      {type == 'input' && getInput()}
      {type == 'select' && getSelect()}
      {type == 'date' && getInputDate()}
    </div>
  );
};

export default Filter;
