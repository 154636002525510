import React from 'react';
import styles from './styles.scss';
import classNames from 'classnames';

interface IProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'disabled';
}

const Button: React.FC<IProps> = ({
  id,
  className,
  children,
  onClick,
  style,
  disabled,
  color = 'default',
}: IProps) => {
  const click = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClick && onClick(event);
  };

  return (
    <button
      id={id}
      // type={'button'}
      className={classNames(styles.button, className, {
        [styles.buttonPrimary]: color === 'primary',
        [styles.buttonSecondary]: color === 'secondary',
        [styles.buttonDanger]: color === 'danger',
        [styles.buttonWarning]: color === 'warning',
        [styles.buttonDisabled]: color === 'disabled',
      })}
      onClick={click}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
