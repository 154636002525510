import React from 'react';
import styles from './styles.scss';
import appStyles from '../../styles/app.scss';
import classNames from 'classnames';

import {
  IFilter,
  ISort,
  intervalOptions,
  typeOptions,
} from '../../services/projectService';

import Filter from '../Filter';
import RangeFilter from '../RangeFilter';

import Row from '../Row';
import Column from '../Column';

const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#404040"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <line x1="9" y1="12" x2="15" y2="12" />
    <line x1="12" y1="9" x2="12" y2="15" />
  </svg>
);

interface IProps {
  onCreateAction: () => void;
  onFilterAction: (filter: IFilter) => void;
  onSortAction: (sortInfo: ISort) => void;
}

const ProjectListActions: React.FC<IProps> = ({
  onCreateAction,
  onFilterAction,
  onSortAction,
}: IProps) => {
  return (
    <Row style={{marginBottom: '1rem'}}>
      <Column style={{flexGrow: 0.1}}>
        <div
          className={appStyles.clickable}
          onClick={onCreateAction}
          style={{ backgroundColor: 'transparent', height: '90%' }}
        >
          {addIcon}
        </div>
      </Column>
      <Column style={{flexGrow: 0.1, marginLeft: '0.8rem'}}>
        <Filter
          name={'code'}
          type={'input'}
          label={'code'}
          filterType={'regex'}
          onFilter={onFilterAction}
        />
      </Column>
      <Column style={{flexGrow: 0.1, marginLeft: '0.8rem'}}>
        <Filter
          name={'client'}
          type={'input'}
          label={'client'}
          filterType={'regex'}
          onFilter={onFilterAction}
        />
      </Column>
      <Column style={{flexGrow: 2, marginLeft: '0.8rem'}}>
        <Filter
          name={'type'}
          type={'select'}
          label={'type'}
          filterType={'exact'}
          options={typeOptions}
          onFilter={onFilterAction}
        />
      </Column>
      <Column  style={{flexGrow: 2, marginLeft: '0.8rem'}}>
        <Filter
          name={'interval'}
          type={'select'}
          label={'interval'}
          filterType={'exact'}
          options={intervalOptions}
          onFilter={onFilterAction}
        />
      </Column>
      <Column  style={{flexGrow: 0.5, marginLeft: '0.8rem'}}>
        <RangeFilter name={'deadline'} onFilter={onFilterAction} />
      </Column>
    </Row>
  );
};

export default ProjectListActions;
