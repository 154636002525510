import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Input: React.FC<IProps> = ({ className, children, style }: IProps) => {
  return (
    <div className={classNames(styles.input, className)} style={style}>
      {children}
    </div>
  );
};

export default Input;
