import React, { useContext } from 'react';
import styles from './styles.scss';

import { NavLink } from 'react-router-dom';
import { pageInfo } from '../../Routing/routes';

import IconOpen from '../../assets/icon_arrow-pipe-right_black.svg';
import IconClose from '../../assets/icon_arrow-pipe-left_black.svg';

import { UserContext } from '../../providers/UserProvider';
import { isAllowedToView } from '../../utils/index';

interface IProps {
  active: boolean;
  switchActive: () => void;
}

const Navigation: React.FC<IProps> = ({ active, switchActive }: IProps) => {
  const user = useContext(UserContext);

  const getNavList = () => {
    let navListItems = pageInfo.map((page, index) => {
      if (!isAllowedToView(page, user)) return null;
      return (
        <li key={page.name} id={page.name} className={styles.navListItem}>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.activeNavLink}
            exact={true}
            to={page.path}
          >
            <span>{active && page.label}</span>
            <img
              className={styles.navListItemIcon}
              src={page.icon}
              alt="nav-icon"
            ></img>
            <span className={styles.tooltip}>{page.label}</span>
          </NavLink>
        </li>
      );
    });

    return <ul className={styles.navList}>{navListItems}</ul>;
  };

  return (
    <div className={styles.navbar + (active ? ' ' + styles.activeNavBar : '')}>
      <div className={styles.navbarToggle} onClick={switchActive}>
        {!active ? (
          <img src={IconOpen} alt="open" />
        ) : (
          <img src={IconClose} alt="close" />
        )}
      </div>
      {getNavList()}
    </div>
  );
};

export default Navigation;
