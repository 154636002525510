import React from 'react';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
}

const ContentContainer: React.FC<IProps> = ({ children }: IProps) => {
  return <div className={styles.container}>{children}</div>;
};

export default ContentContainer;
