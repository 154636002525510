import { IPage } from '../Routing/routes';

export const clickedOnElement = (
  ref: HTMLElement,
  event: MouseEvent,
): boolean => {
  let elInfo = ref.getBoundingClientRect();
  let clickedOnEl =
    event.x >= elInfo.x &&
    event.y >= elInfo.y &&
    event.x <= elInfo.x + elInfo.width &&
    event.y <= elInfo.y + elInfo.height;
  return clickedOnEl;
};

export const sanitize = (string: string) => {
  const map: { [index: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;',
    '/': '&sol;',
    '\\': '&bsol;',
    '`': '&grave;',
  };
  const reg = /[&<>"'/\\`]/gi;
  return string.replace(reg, (match) => map[match]);
};

export const capitalize = (string: string) => {
  if (string.length === 0) return;
  if (string.length === 1) return string.charAt(0).toUpperCase();
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getDomainFromEmail = (email: string) => {
  let splitemail = email.split('@');
  let domain = splitemail[splitemail.length - 1];
  return domain;
};

export const isAllowedToView = (
  page: IPage,
  user: firebase.default.User,
): boolean => {
  if (!page.allowedDomains) return true;
  else if (!user) return;
  if (page.allowedDomains.includes(getDomainFromEmail(user.email))) return true;
  else return false;
};

export const clickedOutside = (
  ref: React.RefObject<any>,
  event: MouseEvent,
): boolean => {
  return !ref.current.contains(event.target);
};
