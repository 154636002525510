export interface IProject {
  allowedDomains: Array<string>;
  url: string;
  code: string;
  client: string;
  type: string;
  name: string;
  contactName: string;
  interval: string;
  deadline: string;
}

export interface IResponse {
  status: number;
  success: boolean;
  errors?: Array<{ name: string; description: string }>;
  message?: string;
  items?: Array<IProject>;
}

export interface IFilter {
  field: string;
  filterType: 'regex' | 'exact' | 'start' | 'end';
  value?: string;
}

export interface ISort {
  field: string;
  order: number;
  type: 'asc' | 'desc';
}

export const allowedDomainsOptions = [
  { value: 'memo2.com', label: 'MeMo2'},
  { value: 'dynata.com', label: 'Dynata' },
  { value: 'panelinzicht.nl', label: 'Panelinzicht' },
];
export const intervalOptions = [
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly'},
  { value: 'Quarterly', label: 'Quarterly'},
  { value: 'Ad_hoc', label: 'Ad_hoc'},
];
export const typeOptions = [
  { value: 'BrandTracker', label: 'BrandTracker'},
  { value: 'CampaignTracker', label: 'CampaignTracker'},
  { value: 'InsightScan', label: 'InsightScan'},
  { value: 'CrossMediaTracker', label: 'CrossMediaTracker'},
  { value: 'BrandHealth', label: 'BrandHealth'},
  { value: 'TwitterEffectStudy', label: 'TwitterEffectStudy'},
  { value: 'RadioTracker', label: 'RadioTracker'},
  { value: 'BrandedContent', label: 'BrandedContent'},
  { value: 'OutdoorTracker', label: 'OutdoorTracker'},
  { value: 'PreTest', label: 'PreTest'},
  { value: 'ConceptScan', label: 'ConceptScan'},
  { value: 'Other', label: 'Other'},
];

const getHost = () => {
  let host: string;
  if (window.location.hostname == 'localhost') host = 'http://localhost:3000';
  else host = 'https://paneltool.api.memo2.space';
  return host;
};

const generalFetch = async (url: string, options: object = {}) => {
  return await fetch(url, options)
    .then((res) => res.json())
    .then((data: IResponse) => data);
};

export const fetchProjectsReq = (email: string) => {
  const host = getHost();
  const location = '/project/index';
  const url = host + location;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
    }),
  };
  return generalFetch(url, options);
};

export const fetchFilteredProjectsReq = (
  filters: Array<IFilter>,
  email: string,
) => {
  const host = getHost();
  const location = '/project/index/filtered';
  let queryString = '';
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
    }),
  };
  filters.forEach((filter, idx) => {
    if (idx > 0) queryString += '&';
    queryString += filter.field + '[' + filter.filterType + ']=' + filter.value;
  });
  const url = host + location + '?' + queryString;
  return generalFetch(url, options);
};

export const saveProjectReq = (project: IProject, email: string) => {
  const host = getHost();
  const location = '/project/save';
  const url = host + location;
  let body: any = project;
  body['email'] = email;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return generalFetch(url, options);
};

export const deleteProjectReq = (projectCode: string, email: string) => {
  const host = getHost();
  const location = '/project/delete/' + projectCode;
  const url = host + location;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  };
  return generalFetch(url, options);
};
