import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Base from '../components/Base';
import ProtectedRoute from '../components/ProtectedRoute';

import { pageInfo } from './routes';

const Routing = () => {
  const getPages = () => {
    return pageInfo.map((page) => {
      if (page.allowedDomains) {
        return <ProtectedRoute key={page.name} pageInfo={page} />;
      } else {
        return (
          <Route
            key={page.name}
            exact
            path={page.path}
            component={page.component}
          />
        );
      }
    });
  };

  return (
    <Router>
      <Base>
        <Switch>{getPages()}</Switch>
      </Base>
    </Router>
  );
};

export default Routing;
