import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const FormBody: React.FC<IProps> = ({ className, children }: IProps) => {
  return (
    <div className={classNames(styles.formBody, className)}>{children}</div>
  );
};

export default FormBody;
