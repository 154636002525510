import React, { useEffect, useRef } from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

import { clickedOutside } from '../../../utils/index';

interface IProps {
  className?: string;
  children: React.ReactNode;
  onClickOutside?: () => void;
}

const PopUpMenu: React.FC<IProps> = ({ className, children, onClickOutside }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      window.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if(clickedOutside(containerRef, event)) {
      onClickOutside && onClickOutside();
    }
  };

  return (
    <div ref={containerRef} className={classNames(styles.popUpMenu, className)}>
      {children}
    </div>
  );
};

export default PopUpMenu;
