import React from 'react';
import styles from './styles.scss';

import Logo from '../../assets/logo_mini_black_2.svg';
import UserAccount from '../UserAccount';

interface IProps {
  title?: string;
  switchNav?: () => void;
}

const Header: React.FC<IProps> = ({ title, switchNav }: IProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {Logo && (
          <div className={styles.logoContainer} onClick={switchNav}>
            <img src={Logo} alt="logo" />
          </div>
        )}
        {title && (
          <div className={styles.titleContainer}>
            <h2>{title}</h2>
          </div>
        )}
        <UserAccount />
      </div>
    </header>
  );
};

export default Header;
