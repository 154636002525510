import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.scss';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGB);

import { Input, InputField } from '../Input';

interface IProps {
  className?: string;
  label?: string;
  onPicked?: (date: Date) => void;
  onPickedStart?: (date: Date) => void;
  onPickedEnd?: (date: Date) => void;
  closeOnPick?: boolean;
  shouldCloseOnSelect?: boolean;
  showWeekNumbers?: boolean;
  value: string;
  selectsRange?: boolean;
  minDate?: Date;
  maxDate?: Date;
  readOnly?: boolean;
}

const CustomDatePicker: React.FC<IProps> = ({
    className,
    label,
    onPicked,
    onPickedStart,
    onPickedEnd,
    closeOnPick,
    selectsRange,
    showWeekNumbers,
    value,
    minDate,
    maxDate,
    readOnly = false,
  }: IProps,
) => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const pointer = useRef<string>('start');

  let mounted = true;

  useEffect(() => {
    return () => (mounted = false);
  }, []);

  const handleSelect = (date: Date) => {
    if (selectsRange) {
      if (pointer.current == 'start') {
        onPickedStart && onPickedStart(date);
        mounted && setStartDate(date);
        pointer.current = 'end';
      } else if (pointer.current == 'end') {
        onPickedEnd && onPickedEnd(date);
        mounted && setEndDate(date);
        pointer.current = 'start';
      }
    } else {
      onPicked && onPicked(date);
      mounted && setStartDate(date);
    }
  };

  const handleChange = (date: Date | null) => {
    if(date === null) {
      if(selectsRange){
        onPickedStart && onPickedStart(null);
        mounted && setStartDate(null)
        onPickedEnd && onPickedEnd(null);
        mounted && setEndDate(null)
      } else {
        onPicked(null)
      }
    }
  };

  return (
    <div className={styles.datepickerWrapper}>
      <div className={styles.reactDatepickerLabel}>{label}</div>
      <DatePicker
        locale={enGB}
        showWeekNumbers={showWeekNumbers}
        selected={startDate}
        value={value ? value : ''}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        onSelect={handleSelect}
        shouldCloseOnSelect={closeOnPick}
        selectsRange={selectsRange}
        placeholderText={'select date'}
        readOnly={readOnly}
        isClearable={true}
      />
    </div>
  );
};

export default CustomDatePicker;
